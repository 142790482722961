<template>
    <div class="row">

      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
              <h5 class="title">XAU Offers</h5>
          </div>
          <div class="card-body table-responsive table-full-width">
            <el-table :data="xauoffers">
                <el-table-column label="Asset" property="asset"></el-table-column>
                <el-table-column label="Price" property="price"></el-table-column>
                <el-table-column label="Size" property="size"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
              <h5 class="title">XAU Bids</h5>
          </div>
          <div class="card-body table-responsive table-full-width">
            <el-table :data="xaubids">
                <el-table-column label="Asset" property="asset"></el-table-column>
                <el-table-column label="Price" property="price"></el-table-column>
                <el-table-column label="Size" property="size"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
              <h5 class="title">XAG Offers</h5>
          </div>
          <div class="card-body table-responsive table-full-width">
            <el-table :data="xagoffers">
                <el-table-column label="Asset" property="asset"></el-table-column>
                <el-table-column label="Price" property="price"></el-table-column>
                <el-table-column label="Size" property="size"></el-table-column>
            </el-table>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
              <h5 class="title">XAG Bids</h5>
          </div>
          <div class="card-body table-responsive table-full-width">
            <el-table :data="xagbids">
                <el-table-column label="Asset" property="asset"></el-table-column>
                <el-table-column label="Price" property="price"></el-table-column>
                <el-table-column label="Size" property="size"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  Vue.use(Table)
  Vue.use(TableColumn)
  import { API } from 'aws-amplify';

  import { Auth } from 'aws-amplify'
  import Amplify from 'aws-amplify';
  import awsconfig from '../../aws-exports';
  Amplify.configure(awsconfig);


  export default {
    async created() {
      this.getQuotesXAU(),
      this.getQuotesXAG(),
      window.setInterval(this.refresh, 2000); 	// Call a function every 10000 milliseconds (OR 10 seconds).
    },
    data () {
      return {
        quotes: [],
        xauoffers: [],
        xaubids: [],
        xagoffers: [],
        xagbids: []
      }
    },
    methods: {
      async getQuotesXAU(){
        const apiName = 'xbapiquote'
        const path = '/quote/XAU'
        const init = {
            headers: {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }
        }
        API
          .get(apiName, path, init)
          .then(response => (
            this.quotes = response['Items'],
            this.xauoffers = this.quotes.filter((quote) => quote.isOffer === true),
            this.xaubids = this.quotes.filter((quote) => quote.isOffer === false)
          ))
      },
      async getQuotesXAG(){
        const apiName = 'xbapiquote'
        const path = '/quote/XAG'
        const init = {
            headers: {
              Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
            }
        }
        API
          .get(apiName, path, init)
          .then(response => (
            this.status = response,
            this.quotes = response['Items'],
            this.xagoffers = this.quotes.filter((quote) => quote.isOffer === true),
            this.xagbids = this.quotes.filter((quote) => quote.isOffer === false)
          ))
      },
      async refresh() {
        this.getQuotesXAU(),
        this.getQuotesXAG()
      }
    }
  }

</script>
<style>

</style>
